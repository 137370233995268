<template>
  <div class="page">
    <div class="main">
      <div class="title">商品列表</div>
      <el-row style="margin-top: 10px; min-height: 580px">
        <el-button type="primary" @click="handleAdd" size="mini"
          ><i class="el-icon-plus"> 新增</i></el-button
        >
        <el-table
          :header-cell-style="rowClass"
          :key="tables.tableKey"
          v-loading="tables.listLoading"
          :data="tables.tableData"
          border
          :cell-style="columnStyle"
          highlight-current-row
          style="width: 100%; margin-top: 20px"
          height="580"
        >
          <el-table-column
            type="index"
            label="序号"
            width="50"
            align="center"
          />
          <el-table-column
            prop="img"
            label="商品图片"
            width="100"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <img :src="scope.row.img" height="80" />
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="名字"
            width="100"
            show-overflow-tooltip
          />
          <el-table-column
            prop="brief"
            label="商品简介"
            width="100"
            show-overflow-tooltip
          />
          <el-table-column
            prop="original_price"
            label="原价"
            width="120"
            show-overflow-tooltip
          />
          <el-table-column
            prop="current_price"
            label="现价"
            width="120"
            show-overflow-tooltip
          />
          <el-table-column prop="status" label="状态" width="80" align="center">
            <div></div>
            <template slot-scope="scope">
              <div v-if="scope.row.status == 0" style="color: red">禁用</div>
              <div v-if="scope.row.status == 1" style="color: #567efa">
                开启
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="auto"
            fixed="right"
            min-width="200px"
          >
            <template slot-scope="scope">
              <i
                class="el-icon-edit"
                @click="handleEdit(scope.row.id)"
                style="margin-left: 20px"
                >编辑</i
              >
              <i
                class="el-icon-close"
                @click="handleDel(scope.row.id)"
                style="margin-left: 20px"
                >删除</i
              >
            </template>
          </el-table-column>
        </el-table>

        <div style="margin-top: 10px">
          <template>
            <div class="block">
              <el-pagination
                layout="prev, pager, next"
                :current-page="tables.currentPage"
                :total="tables.total"
                @current-change="handleCurrentChange"
              />
            </div>
          </template>
        </div>
      </el-row>

      <el-dialog
        :title="dialog.title"
        :visible.sync="dialog.centerDialogVisible"
        :close-on-click-modal="false"
        width="65%"
        @closed="resetForm"
      >
        <el-form
          ref="dataForm"
          :inline="true"
          :model="formData"
          label-position="right"
          label-width="100px"
          style="padding-left: 40px"
        >
          <el-form-item label="名称" prop="name">
            <el-input v-model="formData.name" style="width: 300px" />
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select
              v-model="formData.status"
              class="filter-item"
              placeholder="是否显示"
              style="width: 300px"
            >
              <el-option :key="1" label="显示" :value="1" />
              <el-option :key="0" label="隐藏" :value="0" />
            </el-select>
          </el-form-item>
          <el-form-item label="简介" prop="brief">
            <el-input v-model="formData.brief" style="width: 300px" />
          </el-form-item>
          <el-form-item label="原价" prop="original_price">
            <el-input v-model="formData.original_price" style="width: 300px" />
          </el-form-item>
          <el-form-item label="现价" prop="current_price">
            <el-input v-model="formData.current_price" style="width: 300px" />
          </el-form-item>
          <el-form-item label="商品图" prop="img">
            <el-upload
              action="https://bkgaoshou.com/index.php/admin/upload/images"
              :show-file-list="false"
              :on-success="upload_img"
            >
              <i class="el-icon-plus" />
              <img
                v-if="formData.img"
                :src="formData.img"
                class="avatar"
                width="200px"
              />
            </el-upload>
          </el-form-item>
          <br />
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="resetForm">取 消</el-button>
          <el-button v-if="dialog.title == '编辑'" @click="edit"
            >确定</el-button
          >
          <el-button v-if="dialog.title == '新增'" @click="add">添加</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      tables: {
        tableKey: 0,
        listLoading: false,
        currentPage: 1,
        pageSize: 10,
        total: 1,
        tableData: [],
      },
      dialog: {
        centerDialogVisible: false,
      },
      formData: {
        id: "",
        name: "",
        img: "",
        brief: "",
        original_price: "",
        current_price: "",
        desc: "",
        status: 1,
      },
    };
  },
  
  created() {
    this.getList();
  },
  
  methods: {
    rowClass() {
      return "background:#f2f2f2;color:#4d4d4d";
    },
    columnStyle({ columnIndex }) {
      if (columnIndex == 5) {
        //第三第四列的背景色就改变了2和3都是列数的下标
        return "color:red";
      }
    },
    getList() {
      this.tables.listLoading = true;
      const data = {
        pageSize: this.tables.pageSize,
        pageNum: this.tables.currentPage,
      };
      this.$fecth.post("goods/getData", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.tables.listLoading = false;
          this.tables.tableData = data.lists;
          this.tables.pageSize = data.pageSize;
          this.tables.total = data.total;
          this.tables.currentPage = data.pageNum;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    handleEdit(id) {
      this.dialog.title = "编辑";
      this.formData.id = id;
      const data = {
        id: id,
      };

      this.$fecth.post("goods/getDataById", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.formData = data;
          this.dialog.centerDialogVisible = true;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    edit() {
      const data = {
        id: this.formData.id,
        name: this.formData.name,
        img: this.formData.img,
        brief: this.formData.brief,
        original_price: this.formData.original_price,
        current_price: this.formData.current_price,
        desc: this.formData.desc,
        status: this.formData.status,
      };
      this.$fecth.post("goods/edit", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.resetForm();
          this.getList();
        } else {
          this.showTips(0, msg);
        }
      });
    },
    handleAdd() {
      this.dialog.title = "新增";
      this.dialog.centerDialogVisible = true;
    },
    add() {
      const data = {
        name: this.formData.name,
        img: this.formData.img,
        brief: this.formData.brief,
        original_price: this.formData.original_price,
        current_price: this.formData.current_price,
        desc: this.formData.desc,
        status: this.formData.status,
      };
      this.$fecth.post("goods/add", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.getList();
          this.resetForm();
          this.showTips(1, msg);
        } else {
          this.showTips(0, msg);
        }
      });
    },
    handleDel(id) {
      const data = {
        id: id,
      };
      this.$fecth.post("goods/delete", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.showTips(1, '操作成功');
          this.getList();
        } else {
          this.showTips(0, msg);
        }
      });
    },
    resetForm() {
      this.formData = {
        id: "",
        name: "",
        img: "",
        brief: "",
        original_price: "",
        current_price: "",
        desc: "",
        status: 1,
      };
      this.dialog.centerDialogVisible = false;
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
    handleCurrentChange(val) {
      this.tables.currentPage = val;
      this.getList();
    },
    upload_img(file) {
      if (file.code == 200) {
        this.formData.img = file.data;
      } else {
        this.showTips(0, file.data);
      }
    },
  },
};
</script>
